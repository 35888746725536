<script setup>
import { useMenuStore } from '~/stores/menu';

const menuStore = useMenuStore()
</script>

<template>
    <!-- Menu Mobile -->
    <div class="flex flex-row justify-between h-16 fixed gap-x-4 bottom-0 left-0 right-0 bg-secondary text-white xl:hidden z-30">
        <div id="left" class="flex flex-row justify-around flex-grow">
            <MenuMobileItem @click="menuStore.toggle()">
                <MenuMobileIcon class="mb-2">
                    <div 
                        class="w-5 h-[2px] bg-[currentColor] mt-1 duration-300"
                        :class="{ 'top-rotate': menuStore.menuOpened }">
                    </div>
                    <div 
                        class="w-5 h-[2px] bg-[currentColor] mt-1 duration-300"
                        :class="{ 'move-x': menuStore.menuOpened }">
                    </div>
                    <div 
                        class="w-5 h-[2px] bg-[currentColor] mt-1 duration-300"
                        :class="{ 'bottom-rotate': menuStore.menuOpened }">
                    </div>
                </MenuMobileIcon>

                <MenuMobileText>{{ !menuStore.menuOpened ? 'Menu' : 'Fechar' }}</MenuMobileText>
            </MenuMobileItem>

            <MenuMobileItem>
                <NuxtLink to="/dashboard" class="text-center">
                    <MenuMobileIcon class="group-hover:text-primary mb-2">
                        <Icon name="akar-icons:schedule" size="22" />
                    </MenuMobileIcon>
                    <MenuMobileText class="group-hover:text-primary">
                        Agendamentos
                    </MenuMobileText>
                </NuxtLink>    
            </MenuMobileItem>
        </div>
        <div id="right" class="flex flex-row justify-around flex-grow">
            <MenuMobileItem>
                <NuxtLink to="/diario" class="text-center">
                    <MenuMobileIcon class="group-hover:text-primary mb-2">
                        <Icon name="mingcute:diary-line" size="22" />
                    </MenuMobileIcon>
                    <MenuMobileText class="group-hover:text-primary">
                        Criatório
                    </MenuMobileText>
                </NuxtLink>    
            </MenuMobileItem>

            <MenuMobileItem>
                <NuxtLink to="/psicologa" class="text-center">
                    <MenuMobileIcon class="group-hover:text-primary mb-2">
                        <Icon name="clarity:users-line" size="25" />
                    </MenuMobileIcon>
                    <MenuMobileText class="group-hover:text-primary">
                        Psicólogas
                    </MenuMobileText>
                </NuxtLink>    
            </MenuMobileItem>
        </div>
    </div>
</template>

<style scoped>

.move-x {
    transform: translateX( 30px );
    opacity: 0;
}

.top-rotate {
    transform-origin: center;
    transform: rotateZ( 45deg ) translateY( 6px ) translateX( 1.5px );
}

.bottom-rotate {
    transform-origin: center;
    transform: rotateZ( -45deg ) translateY( -7px ) translateX( 2px );
}

</style>