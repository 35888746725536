<script setup>
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '~/tailwind.config.js'
import FloatingWhatsButton from "~/components/SiteHome/FloatingWhatsButton.vue";
const fullConfig = resolveConfig(tailwindConfig)

useHead({
  meta: [
    {
      name: 'theme-color',
      content: fullConfig.theme.colors.primary,
      'data-react-helmet': true,
    }
  ],
})

</script>

<template>
    <div class="min-h-screen bg-primary">
        <!-- Main Top Navbar Header -->
        <Navbar />
        
        <!-- Bottom mobile menu-->
        <MenuMobile />

        <div class="flex flex-row">
            <!-- Sidebar Menu -->
            <Menu />

            <!-- Site Content -->
            <Content class="mx-4">
                <slot />
            </Content>
        </div>
        <div>
          <FloatingWhatsButton />
        </div>

        <Footer v-if="false" class="xl:ml-[15%] pb-[15%] 2xl:pb-10 ml-0 bg-gray-800 "/>
    </div>
</template>
