<template>
    <div 
        class="flex flex-col last:border-b-0 cursor-pointer">
        <div 
            class="flex justify-between items-center font-bold text-sm pt-4 px-6 text-bzMenuText uppercase" 
            @click="open = !open"
            v-if="$slots.header">
                <slot name="header" />
                <Icon 
                    name="material-symbols:keyboard-arrow-down-rounded" 
                    size="24" 
                    class="duration-300"
                    :class="{ 'rotate-180': open }" />
        </div>

        <Transition name="fade">
            <div 
                class="flex flex-col gap-y-4 px-6 cursor-pointer group overflow-y-hidden"
                v-show="open">
                <slot name="body" />
            </div>
        </Transition>
    </div>
</template>

<script setup>

const open = ref( true )

defineProps( [ 'height' ] )

</script>

<style scoped>

.fade-enter-active {
    animation: drop-animation 300ms reverse;
}

.fade-leave-to {
    animation: drop-animation 300ms;
}

@keyframes drop-animation {
    0% {
        height: v-bind( height );
        opacity: 1;
    }

    100% {
        opacity: 0;
        height: 0;
    }
}


</style>
