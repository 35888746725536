<script setup>
    import { useMenuStore } from '~/stores/menu'

    const menuStore = useMenuStore()
    const props = defineProps(['to'])
</script>

<template>
    <NuxtLink 
        :to="props.to" 
        @click="menuStore.modalClose()" 
        class="flex flex-row gap-x-5 items-center text-secondary hover:text-separator font-bold text-sm">
            <slot />
    </NuxtLink>
</template>