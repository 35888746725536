<template>
    <div 
        
        :class="[
            (
                menuStore.menuOpened
                ? 'xl:w-1/6'
                : '-translate-x-full xl:translate-x-0 xl:w-[15%]'
            ),
            (
                menuStore.targeOpened
                ? 'xl:top-[4.0rem]'
                : 'xl:top-16'
            )

        ]" class="bg-primary xl:bottom-0 xl:flex w-screen fixed z-50 top-0 bottom-16 flex flex-col transition-all duration-500 xl:duration-0 easy-in-out overflow-y-scroll xl:border-r border-opacity-30 border-separator [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
        <!-- -->
        <div class="flex flex-row items-center justify-between h-16 p-4 xl:hidden border-b border-separator border-opacity-30">
            <NavbarLogo />
            <div @click="menuStore.toggle()" class="cursor-pointer text-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-10 h-10">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
        <div class="flex flex-col text-white">
        

            <MenuItem class="gap-y-2">
                <template #body>
                    <div class="w-full h-auto pt-6">
                        <MenuDropdownItem to="/dashboard" class="">
                            <Icon name="lucide:newspaper" size="32" class=""/>
                            Painel
                        </MenuDropdownItem>

                        <MenuDropdownItem to="/agendamento" class="mt-6">
                            <Icon name="akar-icons:schedule" size="32" class=""/>
                            Agendamentos
                        </MenuDropdownItem>

                        <MenuDropdownItem to="/diario" class="mt-6">
                            <Icon name="mingcute:diary-line" size="32" class=""/>
                            Criatório
                        </MenuDropdownItem>

                        <MenuDropdownItem to="/psicologa" class="mt-6">
                            <Icon name="clarity:users-line" size="32" class=""/>
                            Psicólogas
                        </MenuDropdownItem>

                        <MenuDropdownItem to="/pagamento/list" class="mt-6">
                            <Icon name="streamline:money-wallet-money-payment-finance-wallet" size="32" />
                            Pagamentos
                        </MenuDropdownItem>

                        <MenuDropdownItem to="/feedback" class="mt-6">
                            <Icon name="carbon:user-feedback" size="32" />
                            Feedback
                        </MenuDropdownItem>

                        <MenuDropdownItem to="#" @click="downloadContract" class="mt-6" v-if="userStore.termsAccepted">
                            <Icon name="ion:document-text-outline" size="32" />
                            Contrato
                        </MenuDropdownItem>

                        <MenuDropdownItem to="/user/cadastro" class="mt-6">
                            <Icon name="lucide:user" size="32" />
                            Cadastro do paciente
                        </MenuDropdownItem>
                    </div>
                </template>
            </MenuItem>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from '~/stores/user'
import axios from "~/plugins/axios"
import { useMenuStore } from '~/stores/menu'

const menuStore = useMenuStore()
const userStore = useUserStore()
const $axios = axios().provide.axios

const downloadContract = () => {
    $axios({
        url: '/api/v1/contract/download',
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'contrato_terapeutico.pdf';
        link.click();
        URL.revokeObjectURL(url);
    }).catch((error) => {
        console.error('Erro ao baixar o contrato:', error);
    });
}

</script>