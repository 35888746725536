import defaultTheme from 'tailwindcss/defaultTheme'

export default {
  content: [
    './components/**/*.{vue,js}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.{js,ts}',
    './nuxt.config.{js,ts}'
  ],
  theme: {
    extend: {
      fontFamily: {
        sans: [ 'Montserrat', defaultTheme.fontFamily.sans ]
      
      },

      colors: {
        primary: '#fcf3df',
        secondary: '#5d3462',
        
        card: '#1a242d',
        input: '#0f1923',
        separator: '#e05840',

        bzRed: '#115e15',
        bzDarkRed: '#115e15a3',

        bzMenuTextLight: '#bcbfc7',
        bzMenuText: 'white',
        bzMenuTextDark: '#464d5e',

        //cores do site
        purple: "#5d3462",
        beige: "#fcf3df",
        white: "#fafafa",
        coral: "#e05840",
        lilac: "#ad8fb0",
        black: "#000000",
        yellow: "#e0e89b",
        blue: "#65bdba",
      },

       screens: {
        'tablet': '640px',
        'laptop': '1024px',
        'desktop': '1280px',
    },

      backgroundImage: {
        'texture': "url('/assets/texture.png')",
      }

    }
  }
}