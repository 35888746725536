import { defineStore } from "pinia"

export const useMenuStore = defineStore('menu', () => {
    const menuOpened = ref(false)
    const targeOpened = ref(true)

    const toggle = function () {
        menuOpened.value = !menuOpened.value
    }
    
    const targe = function () {
        targeOpened.value = !targeOpened.value
    }

    const modalClose = function () {
        menuOpened.value = false
    }

    const modalOpen = function () {
        menuOpened.value = true
    }

    const getFeatured = (menuName) => {
        return menuName === 'cassino'
    }

    return {
        menuOpened,
        targeOpened,
        toggle,
        getFeatured,
        modalOpen,
        modalClose,
        targe,
    }
})
